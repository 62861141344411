import React, { Component } from 'react';
import logo from './logo.svg';
import axios from 'axios';
import './App.css';
import ReactTooltip from 'react-tooltip'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Collapsible from 'react-collapsible';
import Erb from "./erb-sr.png";
import ReactGA from 'react-ga';


import Delete from "./delete.svg";

let csvToJson = require('convert-csv-to-json');
const request=require('request')
const csv=require('csvtojson')



var stringSimilarity = require('string-similarity');

var Tokenizer = require('react-typeahead').Tokenizer;

var defaultDiacriticsRemovalMap = [
    {'base':'A', 'letters':/[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g},
    {'base':'AA','letters':/[\uA732]/g},
    {'base':'AE','letters':/[\u00C6\u01FC\u01E2]/g},
    {'base':'AO','letters':/[\uA734]/g},
    {'base':'AU','letters':/[\uA736]/g},
    {'base':'AV','letters':/[\uA738\uA73A]/g},
    {'base':'AY','letters':/[\uA73C]/g},
    {'base':'B', 'letters':/[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g},
    {'base':'C', 'letters':/[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g},
    {'base':'D', 'letters':/[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g},
    {'base':'DZ','letters':/[\u01F1\u01C4]/g},
    {'base':'Dz','letters':/[\u01F2\u01C5]/g},
    {'base':'E', 'letters':/[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g},
    {'base':'F', 'letters':/[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g},
    {'base':'G', 'letters':/[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g},
    {'base':'H', 'letters':/[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g},
    {'base':'I', 'letters':/[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g},
    {'base':'J', 'letters':/[\u004A\u24BF\uFF2A\u0134\u0248]/g},
    {'base':'K', 'letters':/[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g},
    {'base':'L', 'letters':/[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g},
    {'base':'LJ','letters':/[\u01C7]/g},
    {'base':'Lj','letters':/[\u01C8]/g},
    {'base':'M', 'letters':/[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g},
    {'base':'N', 'letters':/[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g},
    {'base':'NJ','letters':/[\u01CA]/g},
    {'base':'Nj','letters':/[\u01CB]/g},
    {'base':'O', 'letters':/[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g},
    {'base':'OI','letters':/[\u01A2]/g},
    {'base':'OO','letters':/[\uA74E]/g},
    {'base':'OU','letters':/[\u0222]/g},
    {'base':'P', 'letters':/[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g},
    {'base':'Q', 'letters':/[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g},
    {'base':'R', 'letters':/[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g},
    {'base':'S', 'letters':/[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g},
    {'base':'T', 'letters':/[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g},
    {'base':'TZ','letters':/[\uA728]/g},
    {'base':'U', 'letters':/[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g},
    {'base':'V', 'letters':/[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g},
    {'base':'VY','letters':/[\uA760]/g},
    {'base':'W', 'letters':/[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g},
    {'base':'X', 'letters':/[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g},
    {'base':'Y', 'letters':/[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g},
    {'base':'Z', 'letters':/[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g},
    {'base':'a', 'letters':/[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g},
    {'base':'aa','letters':/[\uA733]/g},
    {'base':'ae','letters':/[\u00E6\u01FD\u01E3]/g},
    {'base':'ao','letters':/[\uA735]/g},
    {'base':'au','letters':/[\uA737]/g},
    {'base':'av','letters':/[\uA739\uA73B]/g},
    {'base':'ay','letters':/[\uA73D]/g},
    {'base':'b', 'letters':/[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g},
    {'base':'c', 'letters':/[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g},
    {'base':'d', 'letters':/[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g},
    {'base':'dz','letters':/[\u01F3\u01C6]/g},
    {'base':'e', 'letters':/[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g},
    {'base':'f', 'letters':/[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g},
    {'base':'g', 'letters':/[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g},
    {'base':'h', 'letters':/[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g},
    {'base':'hv','letters':/[\u0195]/g},
    {'base':'i', 'letters':/[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g},
    {'base':'j', 'letters':/[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g},
    {'base':'k', 'letters':/[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g},
    {'base':'l', 'letters':/[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g},
    {'base':'lj','letters':/[\u01C9]/g},
    {'base':'m', 'letters':/[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g},
    {'base':'n', 'letters':/[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g},
    {'base':'nj','letters':/[\u01CC]/g},
    {'base':'o', 'letters':/[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g},
    {'base':'oi','letters':/[\u01A3]/g},
    {'base':'ou','letters':/[\u0223]/g},
    {'base':'oo','letters':/[\uA74F]/g},
    {'base':'p','letters':/[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g},
    {'base':'q','letters':/[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g},
    {'base':'r','letters':/[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g},
    {'base':'s','letters':/[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g},
    {'base':'t','letters':/[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g},
    {'base':'tz','letters':/[\uA729]/g},
    {'base':'u','letters':/[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g},
    {'base':'v','letters':/[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g},
    {'base':'vy','letters':/[\uA761]/g},
    {'base':'w','letters':/[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g},
    {'base':'x','letters':/[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g},
    {'base':'y','letters':/[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g},
    {'base':'z','letters':/[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g}
];
var changes;

class Zivnosti extends Component{
    constructor(props){
        super(props);
        this.state = {
            zivnosti: new Array(),
            actual: "",
            remeselneZivnosti: "",
            viazaneZivnosti: "",
            volneZivnosti: "",
            viazaneZivnosti2: "",
            finded: "",
            inputZivnost: "",
            price:0,
            pridat: false,


            vyska: 5000,
            rozsah: 5000,
            vyska2: "",
            rozsah2: "",
            counter: 0,
            add: false,
            osoba: "fyzicka",
            titulPredMenom: "",
            titulZaMenom: "",
            meno: "",
            priezvisko: "",
            ulica: "",
            supCislo: "",
            obec: "",
            psc: "",
            datumNarodenie: "",
            rodneCislo: "",
            rodneCislo2: "",
            stat: "",

            vyskaVkladu: 5000,
            podielSpolocnost: 100,
            rozsahSplatenia: 5000,
            meno2: "",
            priezvisko2: "",
            ulica2: "",
            statFirma: "",
            statFirma2: "",
            supCislo2: "",
            obec2: "",
            psc2: "",
            datumNarodenie2: "",
            stat2: "",
            totoznost2: "",
            cisloTotoznosti2: "",
            vyskaVkladu2: "",
            podielSpolocnost2: "",
            rozsahSplatenia2: "",
            spravcaVkladu: true,
            konatel: true,
            miestoNarodenia: "",
            miestoNarodenia2: "",
            statnaPrislusnost: "",
            statnaPrislusnost2: "",
            rodnePriezvisko: "",
            rodnePriezvisko2: "",
            predchadzajucePriezvisko: "",
            predchadzajucePriezvisko2: "",
            menoOtca: "",
            menoOtca2: "",
            priezviskoOtca: "",
            priezviskoOtca2: "",
            menoMatky: "",
            menoMatky2: "",
            rodnePriezviskoMatky: "",
            rodnePriezviskoMatky2: "",
            spolocnici: new Array(),
            pscFirma2: "",
            pscFirma: "",
            obecFirma: "",
            obecFirma2: "",
            supisneCisloFirma: "",
            supisneCisloFirma2: "",
            ulicaFirma: "",
            ulicaFirma2: "",
            ico: "",
            ico2: "",
            obchodneMeno: "",
            obchodneMeno2: "",
            menoKonatel: "",
            menoKonatel2: "",
            priezviskoKonatel: "",
            priezviskoKonatel2: "",
            datumNarodeniaFirma: "",
            datumNarodeniaFirma2: "",
            rodneCisloFirma: "",
            rodneCisloFirma2: "",
            vyskaVkladuFirma: 5000,
            vyskaVkladuFirma2: "",
            podielSpolocnostFirma: 100,
            podielSpolocnostFirma2: "",
            rozsahSplateniaFirma: 5000,
            rozsahSplateniaFirma2: "",
            vyskaVkladu2: "",
            edit: false,
            edittype: "",
            editid: "",
            notallowed: "",
            uzivatelVyhod: "",
            osoby: new Array(),
            prevadzkaren: false,
            prevulica: "",
            prevcislo: "",
            prevobec: "",
            prevpsc: "",
            prevulica2: "",
            prevcislo2: "",
            prevobec2: "",
            prevpsc2: "",
            totoznost: "",
            totoznost2: "",
            cisloTotoznosti: "",
            cisloTotoznosti2: "",
            actualzastupca: "",
            choose: false,
            osvedcenie: new Array(),

        }




        this.prevadzkaren = this.prevadzkaren.bind(this);
        this.handleZivnost = this.handleZivnost.bind(this);
        this.delete = this.delete.bind(this);
        this.compareString = this.compareString.bind(this);
        this.addSelection = this.addSelection.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.minuscount = this.minuscount.bind(this);
        this.handlePridat = this.handlePridat.bind(this);







        this.handleEdit = this.handleEdit.bind(this);
        this.handleZastupca = this.handleZastupca.bind(this);
        this.handleRozsahSplateniaFirma = this.handleRozsahSplateniaFirma.bind(this);
        this.handlePodielSpolocnostFirma = this.handlePodielSpolocnostFirma.bind(this);
        this.handleMenoKonatel = this.handleMenoKonatel.bind(this);
        this.handlePriezviskoKonatel = this.handlePriezviskoKonatel.bind(this);
        this.handleIco = this.handleIco.bind(this);
        this.handleObchodneMeno = this.handleObchodneMeno.bind(this);
        this.handleUlicaFirma = this.handleUlicaFirma.bind(this);
        this.handlePscFirma = this.handlePscFirma.bind(this);
        this.handleObecFirma = this.handleObecFirma.bind(this);
        this.handleFirmaSubmit = this.handleFirmaSubmit.bind(this);
        this.handleFirmaName = this.handleFirmaName.bind(this);
        this.handleFirmaForm = this.handleFirmaForm.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleSidlo = this.handleSidlo.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePriezvisko = this.handlePriezvisko.bind(this);
        this.handleMeno = this.handleMeno.bind(this);
        this.handleUlica = this.handleUlica.bind(this);
        this.handleMenoMatky = this.handleMenoMatky.bind(this);
        this.handleUlica = this.handleUlica.bind(this);
        this.handleSupisneCislo = this.handleSupisneCislo.bind(this);
        this.handleOrientacneCislo = this.handleOrientacneCislo.bind(this);
        this.handleObec = this.handleObec.bind(this);
        this.handlePsc = this.handlePsc.bind(this);
        this.handleVlastnik = this.handleVlastnik.bind(this);
        this.replaceDiacritics = this.replaceDiacritics.bind(this);
        this.handleDruh = this.handleDruh.bind(this);
        this.handleProgram = this.handleProgram.bind(this);
        this.handleVyska = this.handleVyska.bind(this);
        this.handleRozsah = this.handleRozsah.bind(this);
        this.addSpolocnik = this.addSpolocnik.bind(this);
        this.handleOsoba = this.handleOsoba.bind(this);
        this.handleTitulPredMenom = this.handleTitulPredMenom.bind(this);
        this.handleTitulZaMenom = this.handleTitulZaMenom.bind(this);
        this.handleStat = this.handleStat.bind(this);
        this.handleDatumNarodenia = this.handleDatumNarodenia.bind(this);
        this.handleSupCislo = this.handleSupCislo.bind(this);
        this.handleTotoznost = this.handleTotoznost.bind(this);
        this.handleCisloTotoznosti = this.handleCisloTotoznosti.bind(this);
        this.handleVyskaVladu = this.handleVyskaVladu.bind(this);
        this.handlePodielSpolocnost = this.handlePodielSpolocnost.bind(this);
        this.handleRozsahSplatenia = this.handleRozsahSplatenia.bind(this);
        this.handleRodneCislo = this.handleRodneCislo.bind(this);
        this.handleKonatel = this.handleKonatel.bind(this);
        this.handleMiestoNarodenia = this.handleMiestoNarodenia.bind(this);
        this.handleSpravcaVkladu = this.handleSpravcaVkladu.bind(this);
        this.handleStatnaPrislusnost = this.handleStatnaPrislusnost.bind(this);
        this.handleRodnePriezvisko = this.handleRodnePriezvisko.bind(this);
        this.handlePredchadzajucePriezvisko = this.handlePredchadzajucePriezvisko.bind(this);
        this.handleMenoOtca = this.handleMenoOtca.bind(this);
        this.handlePriezviskoOtca = this.handlePriezviskoOtca.bind(this);
        this.handlePriezviskoMatky = this.handlePriezviskoMatky.bind(this);
        this.handleRodnePriezviskoMatky = this.handleRodnePriezviskoMatky.bind(this);
        this.handleStatFirma = this.handleStatFirma.bind(this);
        this.handleVyskaVkladuFirma = this.handleVyskaVkladuFirma.bind(this);
        this.handleSupisneCisloFirma = this.handleSupisneCisloFirma.bind(this);
        this.handleRodneCisloFirma = this.handleRodneCisloFirma.bind(this);
        this.handleDatumNarodeniaFirma = this.handleDatumNarodeniaFirma.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.saveedit = this.saveedit.bind(this);
        this.handleUzivatelVyhod = this.handleUzivatelVyhod.bind(this);
        this.handleKonanieKonatelov = this.handleKonanieKonatelov.bind(this);
        this.handlePridatZastupcu = this.handlePridatZastupcu.bind(this);
        this.handlePrevUlica = this.handlePrevUlica.bind(this);
        this.handlePrevCislo = this.handlePrevCislo.bind(this);
        this.handlePrevObec = this.handlePrevObec.bind(this);
        this.handlePrevPsc = this.handlePrevPsc.bind(this);
        this.handleChoose = this.handleChoose.bind(this);
        this.volne = this.volne.bind(this);
        this.viazane = this.viazane.bind(this);
        this.remeselne = this.remeselne.bind(this);
        this.onChange = this.onChange.bind(this);
        this.createImage = this.createImage.bind(this);
        this.delosvedcenie = this.delosvedcenie.bind(this);

    }

    delosvedcenie(index){
        var osvedcenia = this.state.osvedcenie;

        osvedcenia.splice(index, 1);

        this.setState({
            osvedcenie: osvedcenia,
        });
    }


    onChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.createImage(files[0]);
    }
    createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {

            var  osvedcenie = this.state.osvedcenie;
            osvedcenie.push({path: file.name, data: e.target.result});

            console.log(osvedcenie);
            console.log(file.name)

            this.setState({
                osvedcenie: osvedcenie,
            })
        };

        reader.readAsDataURL(file);

    }

    handleZastupca(event){
        this.setState({actualzastupca: event.target.value});
    }
    handleChoose(){
        this.setState({choose: !this.state.choose})
    }



    handlePrevUlica(event){
        this.setState({prevulica: event.target.value});
    }
    handlePrevCislo(event){
        this.setState({prevcislo: event.target.value});
    }
    handlePrevObec(event){
        this.setState({prevobec: event.target.value})
    }
    handlePrevPsc(event){
        this.setState({prevpsc: event.target.value})
    }

    prevadzkaren(event){
        if(event.target.value == 1) {
            this.setState({prevadzkaren: true});
        }
        else{
            this.setState({prevadzkaren: false});
        }
    }

    generateHtml = (x,y,z) => {
        console.log("test");
        return (<div>1</div>);

        // return <a  onClick={() => this.addSelection(x, y, z)}></a>;
    }


    //PRIDANEEEEEE

    isNumeric(num){
        return !isNaN(num)
    }


    handlePridatZastupcu(event){


        if (this.state.meno == "") {
            this.setState({
                meno2: "Toto pole je povinné"
            });
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                meno2: ""
            })
        }
        if (this.state.priezvisko == "") {
            this.setState({
                priezvisko2: "Toto pole je povinné"
            })
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                priezvisko2: ""
            })
        }
        if (this.state.ulica == "") {
            this.setState({
                ulica2: "Toto pole je povinné"
            })
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                ulica2: ""
            })
        }
        if (this.state.supCislo == "") {
            this.setState({
                supCislo2: "Toto pole je povinné"
            })
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                supCislo2: ""
            })
        }
        if (this.state.obec == "") {
            this.setState({
                obec2: "Toto pole je povinné"
            })
            event.preventDefault();
        }
        else {
            this.setState({
                obec2: ""
            })
        }
        if (this.state.psc == "") {
            this.setState({
                psc2: "Toto pole je povinné"
            })
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                psc2: ""
            })
        }
        if (this.state.datumNarodenie == "") {
            this.setState({
                datumNarodenie2: "Toto pole je povinné"
            })
            event.preventDefault();
            return;

        }
        else {
            this.setState({
                datumNarodeni2: ""
            })
        }
        if (this.state.stat == "") {
            this.setState({
                stat2: "Toto pole je povinné"
            })
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                stat2: ""
            })
        }

        if (this.state.rodneCislo == "") {
            this.setState({
                rodneCislo2: "Toto pole je povinné"
            })
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                rodneCislo2: ""
            })
        }

        if (this.state.miestoNarodenia == "") {
            this.setState({
                miestoNarodenia2: "Toto pole je povinné",
            })
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                miestoNarodenia2: ""
            })
        }

        if (this.state.statnaPrislusnost == "") {
            this.setState({
                statnaPrislusnost2: "Toto pole je povinné",
            })
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                statnaPrislusnost2: ""
            })
        }

        if (this.state.rodnePriezvisko == "") {
            this.setState({
                rodnePriezvisko2: "Toto pole je povinné",
            })
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                rodnePriezvisko2: ""
            })
        }

        if (this.state.totoznost == "") {
            this.setState({
                totoznost2: "Toto pole je povinné"
            })
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                totoznost2: ""
            })
        }
        if (this.state.cisloTotoznosti == "") {
            this.setState({
                cisloTotoznosti2: "Toto pole je povinné"
            })
            event.preventDefault();
        }

        if (this.state.menoOtca == "") {
            this.setState({
                menoOtca2: "Toto pole je povinné",
            })
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                menoOtca2: ""
            })
        }
        if (this.state.priezviskoOtca == "Toto pole je povinné") {
            this.setState({
                priezviskoOtca2: "",
            })
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                priezviskoOtca2: ""
            })
        }

        if (this.state.menoMatky == "") {
            this.setState({
                menoMatky2: "Toto pole je povinné",
            })
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                menoMatky2: ""
            })
        }

        if (this.state.priezviskoMatky == "") {
            this.setState({
                priezviskoMatky2: "Toto pole je povinné",
            })
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                priezviskoMatky2: ""
            })
        }
        if (this.state.rodnePriezviskoMatky == "") {
            this.setState({
                rodnePriezviskoMatky2: "Toto pole je povinné",
            })
            event.preventDefault();
            return;
        }
        else {
            this.setState({
                rodnePriezviskoMatky2: ""
            })
        }


        var datas = {
            titul: this.state.titulPredMenom,
            meno: this.state.meno,
            priezvisko: this.state.priezvisko,
            titul2: this.state.titulZaMenom,
            ulica: this.state.ulica,
            supCislo: this.state.supCislo,
            obec: this.state.obec,
            psc: this.state.psc,
            stat: this.state.stat,
            datumNarodenia: this.state.datumNarodenie,
            totoznost: this.state.totoznost,
            cisloTotoznosti: this.state.cisloTotoznosti,

            rodneCislo: this.state.rodneCislo,

            registerTrestov: {
                miestoNarodenia: this.state.miestoNarodenia,
                statnaPrislusnost: this.state.statnaPrislusnost,
                rodnePriezvisko: this.state.rodnePriezvisko,
                predchadzajucePriezvisko: this.state.predchadzajucePriezvisko,
                menoOtca: this.state.menoOtca,
                priezviskoOtca: this.state.priezviskoOtca,
                menoMatky: this.state.menoMatky,
                priezviskoMatky: this.state.priezviskoMatky,
                rodnePriezviskoMatky: this.state.rodnePriezviskoMatky
            }

        }

        this.setState({
            titulPredMenom: "",
            meno: "",
            priezvisko: "",
            titulZaMenom: "",
            ulica: "",
            supCislo: "",
            obec:"",
            psc: "",
            stat: "",
            datumNarodenie: "",
            rodneCislo: "",
            miestoNarodenia: "",
            statnaPrislusnost: "",
            rodnePriezvisko: "",
            predchadzajucePriezvisko: "",
            menoOtca: "",
            priezviskoOtca: "",
            menoMatky: "",
            totoznost: "",
            cisloTotoznosti: "",
            priezviskoMatky: "",
            rodnePriezviskoMatky: "",

        });

        var osoby = this.state.osoby;
        osoby.unshift(datas);



        this.setState({
            add: false,
            osoby: osoby,
            pridat: false,
        })


        event.preventDefault();
    }


    handleUzivatelVyhod(event){

        this.setState({
            uzivatelVyhod: event.target.value,
        })
    }
    handleKonanieKonatelov(event){
        this.setState({
            konanieKonatelov: event.target.value,
        })
    }


    handleDelete(id){
        var spolocnici = this.state.spolocnici;

        spolocnici.splice(id, 1);

        this.setState({spolocnici: spolocnici});
    }

    saveedit(){

        var spolocnici = this.state.spolocnici;

        var id = 0;

        spolocnici.forEach(function (e) {
            if(id == this.state.editid){


                if(e.osoba == "fyzicka"){

                    e.osoba = this.state.osoba;
                    e.titul = this.state.titulPredMenom;
                    e.meno = this.state.meno;
                    e.priezvisko = this.state.priezvisko;
                    e.titul2 = this.state.titulZaMenom;
                    e.ulica = this.state.ulica;
                    e.supCislo = this.state.supCislo;
                    e.obec = this.state.obec;
                    e.psc = this.state.psc;
                    e.stat = this.state.stat;
                    e.datumNarodenia = this.state.datumNarodenie;
                    e.rodneCislo = this.state.rodneCislo;
                    e.typDokladuTotoznosti = this.state.totoznost;
                    e.cisloDokladuTotoznosti = this.state.cisloTotoznosti;
                    e.vyskaVkladu = this.state.vyskaVkladu;
                    e.podielSpolocnost = this.state.podielSpolocnost;
                    e.rozsahSplatenia = this.state.rozsahSplatenia;
                    e.konatel = this.state.konatel;
                    e.spravcaVkladu = this.state.spravcaVkladu;
                    e.registerTrestov.miestoNarodenia = this.state.miestoNarodenia;
                    e.registerTrestov.statnaPrislusnost = this.state.statnaPrislusnost;
                    e.registerTrestov.rodnePriezvisko = this.state.rodnePriezvisko;
                    e.registerTrestov.predchadzajucePriezvisko = this.state.predchadzajucePriezvisko;
                    e.registerTrestov.menoOtca = this.state.menoOtca;
                    e.registerTrestov.priezviskoOtca = this.state.priezviskoOtca;
                    e.registerTrestov.menoMatky = this.state.menoMatky;
                    e.registerTrestov.rodnePriezviskoMatky = this.state.rodnePriezviskoMatky;

                    this.setState({spolocnici: spolocnici,
                        edit: false,
                    })





                }
                else if(e.osoba == "pravnicka"){



                    e.osoba = this.state.osoba;
                    e.ico = this.state.ico;
                    e.obchodneMeno = this.state.obchodneMeno;
                    e.ulica = this.state.ulicaFirma;
                    e.supisneCisloFirma = this.state.supisneCisloFirma;
                    e.obec = this.state.obecFirma;
                    e.psc = this.state.pscFirma;
                    e.stat = this.state.statFirma;
                    e.konatel.meno = this.state.menoKonatel;
                    e.konatel.priezvisko = this.state.priezviskoKonatel;
                    e.konatel.datumNarodenia = this.state.datumNarodeniaFirma;
                    e.konatel.rodneCislo = this.state.rodneCisloFirma;
                    e.vyskaVkladuFirma = this.state.vyskaVkladuFirma;
                    e.podielSpolocnostFirma = this.state.podielSpolocnostFirma;
                    e.rozsahSplateniaFirma = this.state.rozsahSplateniaFirma

                    this.setState({spolocnici: spolocnici,
                        edit: false,
                    })



                }

            }
            id++;
        }.bind(this));

    }
    handleEdit(id){
        this.setState({edit: true,
            editid: id
        });

        var spolocnici = this.state.spolocnici;
        var ide=0;
        spolocnici.forEach(function (e) {
            if(ide==id){


                this.setState({edittype: e.osoba});


                if(e.osoba == "fyzicka") {

                    this.setState({
                        osoba: e.osoba,
                        titulPredMenom: e.titul,
                        meno: e.meno,
                        priezvisko: e.priezvisko,
                        titulZaMenom: e.titul2,
                        ulica: e.ulica,
                        supCislo: e.supCislo,
                        obec: e.obec,
                        psc: e.psc,
                        stat: e.stat,
                        datumNarodenie: e.datumNarodenia,
                        rodneCislo: e.rodneCislo,
                        totoznost: e.typDokladuTotoznosti,
                        cisloTotoznosti: e.cisloDokladuTotoznosti,
                        vyskaVkladu: e.vyskaVkladu,
                        podielSpolocnost: e.podielSpolocnost,
                        rozsahSplatenia: e.rozsahSplatenia,
                        konatel: e.konatel,
                        spravcaVkladu: e.spravcaVkladu,
                        miestoNarodenia: e.registerTrestov.miestoNarodenia,
                        statnaPrislusnost: e.registerTrestov.statnaPrislusnost,
                        rodnePriezvisko: e.registerTrestov.rodnePriezvisko,
                        predchadzajucePriezvisko: e.registerTrestov.predchadzajucePriezvisko,
                        menoOtca: e.registerTrestov.menoOtca,
                        priezviskoOtca: e.registerTrestov.priezviskoOtca,
                        menoMatky: e.registerTrestov.menoMatky,
                        rodnePriezviskoMatky: e.registerTrestov.rodnePriezviskoMatky,


                    });
                }

                else if(e.osoba == "pravnicka"){

                    this.setState({

                        osoba: e.osoba,
                        ico: e.ico,
                        obchodneMeno: e.obchodneMeno,
                        ulicaFirma: e.ulica,
                        supisneCisloFirma: e.supisneCisloFirma,
                        obecFirma: e.obec,
                        pscFirma: e.psc,
                        statFirma: e.stat,
                        menoKonatel: e.konatel.meno,
                        priezviskoKonatel: e.konatel.priezvisko,
                        datumNarodeniaFirma: e.konatel.datumNarodenia,
                        rodneCisloFirma: e.konatel.rodneCislo,
                        vyskaVkladuFirma: e.vyskaVkladuFirma,
                        podielSpolocnostFirma: e.podielSpolocnostFirma,
                        rozsahSplateniaFirma: e.rozsahSplateniaFirma,

                    });
                }




            }

            ide++;
        }.bind(this));


    }

    handleSubmitForm(event) {
        if(this.state.spolocnici.length == 0){
            this.setState({
                notallowed: "Vložte minimálne jedného zakladateľa (spoločníka).",
            });
            event.preventDefault();
            return;
        }





        var datas = {
            imanie: this.state.vyskaVkladuFirma,
            splatene: this.state.rozsahSplateniaFirma,
            spolocnici: this.state.spolocnici
        }


        this.props.makeThird();

        this.props.secondSubmit(datas);
        event.preventDefault();
    }


    handleDatumNarodeniaFirma(event){
        this.setState({datumNarodeniaFirma: event.target.value})
    }

    handleRodneCisloFirma(event){
        this.setState({rodneCisloFirma: event.target.value})
    }
    handleVyskaVkladuFirma(event){
        this.setState({vyskaVkladuFirma: event.target.value})
    }
    handlePodielSpolocnostFirma(event){
        this.setState({podielSpolocnostFirma: event.target.value})
    }
    handleRozsahSplateniaFirma(event){
        this.setState({rozsahSplateniaFirma: event.target.value})
    }
    handleMenoKonatel(event){
        this.setState({menoKonatel: event.target.value})
    }
    handlePriezviskoKonatel(event){
        this.setState({priezviskoKonatel: event.target.value})
    }
    handleObchodneMeno(event){
        this.setState({obchodneMeno: event.target.value})
    }
    handleIco(event){
        this.setState({ico: event.target.value})
    }
    handleUlicaFirma(event){
        this.setState({ulicaFirma: event.target.value})
    }
    handleSupisneCisloFirma(event){
        this.setState({supisneCisloFirma: event.target.value})
    }
    handleObecFirma(event){
        this.setState({obecFirma: event.target.value})
    }
    handleStatFirma(event){
        this.setState({statFirma: event.target.value})
    }
    handleRodnePriezviskoMatky(event){
        this.setState({rodnePriezviskoMatky: event.target.value})
    }
    handleMenoMatky(event){
        this.setState({
            menoMatky: event.target.value,
        })
    }

    handlePscFirma(event){
        this.setState({pscFirma: event.target.value})
    }
    handlePriezviskoMatky(event){
        this.setState({
            priezviskoMatky: event.target.value,
        })
    }
    handlePriezviskoOtca(event){
        this.setState({
            priezviskoOtca: event.target.value,
        })
    }
    handlePredchadzajucePriezvisko(event){
        this.setState({
            predchadzajucePriezvisko: event.target.value,
        })
    }

    handleMenoOtca(event){
        this.setState({
            menoOtca: event.target.value,
        })
    }

    handleStatnaPrislusnost(event){
        this.setState({statnaPrislusnost: event.target.value})
    }

    handleRodnePriezvisko(event){
        this.setState({rodnePriezvisko: event.target.value})
    }

    handleMiestoNarodenia(event){
        this.setState({miestoNarodenia: event.target.value})
    }
    handleKonatel(event){
        this.setState({konatel: !this.state.konatel})
    }

    handleSpravcaVkladu(event){
        if(!this.state.spravcaVkladu == false && this.state.counter == 0){
            this.setState({spravcaVkladu2: "Správca vkladu musí byť ustanovený ak je zakladateľ len jeden!"})
        }
        else{
            this.setState({spravcaVkladu2: ""})
        }
        this.setState({spravcaVkladu: !this.state.spravcaVkladu})
    }

    handleRodneCislo(event){
        this.setState({rodneCislo: event.target.value})
    }
    handleVyskaVladu(event){
        if(this.state.counter == 0 && event.target.value < this.state.vyska){
            var str = "Minimálna hodnota: " + this.state.vyska;
            this.setState({vyskaVkladu2: str});
        }
        else if(this.state.counter > 0 && event.target.value < 750 ){
            var str = "Minimálna hodnota: 750";
            this.setState({vyskaVkladu2: str});
        }
        else{
            this.setState({vyskaVkladu2: ""});
        }
        this.setState({vyskaVkladu: event.target.value})
    }
    handlePodielSpolocnost(event){
        this.setState({podielSpolocnost: event.target.value})
    }
    handleRozsahSplatenia(event){
        this.setState({rozsahSplatenia: event.target.value})
    }


    handleCisloTotoznosti(event){
        this.setState({
            cisloTotoznosti: event.target.value,
        })
    }
    handleTotoznost(event){
        this.setState({
            totoznost:event.target.value,
        })
    }
    addSpolocnik(){
        this.setState({
            counter: this.state.counter++,
            add: true,
        })
    }

    handleStat(event){
        this.setState({
            stat: event.target.value,
        })
    }
    handleDatumNarodenia(event){
        this.setState({
            datumNarodenie: event.target.value,
        })
    }

    handleUlica(event){
        this.setState({
            ulica: event.target.value,
        })
    }
    handlePriezvisko(event){
        this.setState({
            priezvisko: event.target.value,
        })
    }
    handleMeno(event){
        this.setState({
            meno: event.target.value,
        })
    }

    handleTitulZaMenom(event){
        this.setState({
            titulZaMenom:event.target.value,
        })
    }
    handleTitulPredMenom(event){
        this.setState({
            titulPredMenom: event.target.value,
        })
    }
    handleVlastnik(event){
        this.setState({vlastnik: event.target.value})
    }
    handleRozsah(event){
        if(this.state.vyska < event.target.value){
            var str = "Maximálna hodnota: " + this.state.vyska;
            this.setState({rozsah2: str});
        }
        else{
            this.setState({rozsah2: ""});
        }
        this.setState({rozsah: event.target.value});
        this.setState({rozsahSplatenia: event.target.value});
        this.setState({rozsahSplateniaFirma: event.target.value});

    }
    handleVyska(event){


        if(event.target.value < this.state.rozsah){
            var str = "Maximálna hodnota: " + event.target.value;
            this.setState({rozsah2: str});
        }
        else{
            this.setState({rozsah2: ""});
        }




        if(event.target.value < 5000){
            NotificationManager.warning(null, "Minimálna hodnota je 5000");
            this.setState({vyska2: "Minimálna hodnota je 5000€"});
        }
        else{
            this.setState({vyska2: ""});
        }
        this.setState({
            vyska: event.target.value,vyskaVkladu: event.target.value,
            vyskaVkladuFirma: event.target.value,
        });
    }

    handleDruh(event){
        this.setState({druh: event.target.value})
    }
    handleUlica(event){
        this.setState({
            ulica: event.target.value,
        })
    }
    handleSupisneCislo(event){
        this.setState({
            supisneCislo: event.target.value,
        })
    }
    handleOrientacneCislo(event){
        this.setState({
            orientacneCislo: event.target.value,
        })
    }
    handleObec(event){
        this.setState({
            obec: event.target.value,
        })
    }
    handlePsc(event){
        this.setState({
            psc: event.target.value,
        })
    }
    handleSidlo(event){
        this.setState({
            sidlo: event.target.value,
        })
    }
    handleProgram(event){
        this.setState({program: event.target.value})
    }
    handleSupCislo(event){
        this.setState({
            supCislo: event.target.value,
        })
    }

    handleSubmit(event){




        if(this.state.osoba == 'fyzicka') {

            if (this.state.meno == "") {
                this.setState({
                    meno2: "Toto pole je povinné"
                });
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    meno2: ""
                })
            }
            if (this.state.priezvisko == "") {
                this.setState({
                    priezvisko2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    priezvisko2: ""
                })
            }
            if (this.state.ulica == "") {
                this.setState({
                    ulica2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    ulica2: ""
                })
            }
            if (this.state.supCislo == "") {
                this.setState({
                    supCislo2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    supCislo2: ""
                })
            }
            if (this.state.obec == "") {
                this.setState({
                    obec2: "Toto pole je povinné"
                })
                event.preventDefault();
            }
            else {
                this.setState({
                    obec2: ""
                })
            }
            if (this.state.psc == "") {
                this.setState({
                    psc2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    psc2: ""
                })
            }
            if (this.state.datumNarodenie == "") {
                this.setState({
                    datumNarodenie2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;

            }
            else {
                this.setState({
                    datumNarodeni2: ""
                })
            }
            if (this.state.stat == "") {
                this.setState({
                    stat2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    stat2: ""
                })
            }
            if (this.state.totoznost == "") {
                this.setState({
                    totoznost2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    totoznost2: ""
                })
            }
            if (this.state.cisloTotoznosti == "") {
                this.setState({
                    cisloTotoznosti2: "Toto pole je povinné"
                })
                event.preventDefault();
            }
            else {
                this.setState({
                    cisloTotoznosti2: ""
                })
            }
            if (this.state.vyskaVkladu == "") {
                this.setState({
                    vyskaVkladu2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    vyskaVkladu2: ""
                })
            }
            if (this.state.podielSpolocnost == "") {
                this.setState({
                    podielSpolocnost2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    podielSpolocnost2: ""
                })
            }
            if (this.state.rozsahSplatenia == "") {
                this.setState({
                    rozsahSplatenia2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    rozsahSplatenia2: ""
                })
            }
            if (this.state.rodneCislo == "") {
                this.setState({
                    rodneCislo2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    rodneCislo2: ""
                })
            }

            if (this.state.miestoNarodenia == "") {
                this.setState({
                    miestoNarodenia2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    miestoNarodenia2: ""
                })
            }

            if (this.state.statnaPrislusnost == "") {
                this.setState({
                    statnaPrislusnost2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    statnaPrislusnost2: ""
                })
            }

            if (this.state.rodnePriezvisko == "") {
                this.setState({
                    rodnePriezvisko2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    rodnePriezvisko2: ""
                })
            }



            if (this.state.menoOtca == "") {
                this.setState({
                    menoOtca2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    menoOtca2: ""
                })
            }
            if (this.state.priezviskoOtca == "Toto pole je povinné") {
                this.setState({
                    priezviskoOtca2: "",
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    priezviskoOtca2: ""
                })
            }

            if (this.state.menoMatky == "") {
                this.setState({
                    menoMatky2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    menoMatky2: ""
                })
            }

            if (this.state.priezviskoMatky == "") {
                this.setState({
                    priezviskoMatky2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    priezviskoMatky2: ""
                })
            }
            if (this.state.rodnePriezviskoMatky == "") {
                this.setState({
                    rodnePriezviskoMatky2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    rodnePriezviskoMatky2: ""
                })
            }


            var datas = {
                osoba: this.state.osoba,
                titul: this.state.titulPredMenom,
                meno: this.state.meno,
                priezvisko: this.state.priezvisko,
                titul2: this.state.titulZaMenom,
                ulica: this.state.ulica,
                supCislo: this.state.supCislo,
                obec: this.state.obec,
                psc: this.state.psc,
                stat: this.state.stat,
                datumNarodenia: this.state.datumNarodenie,
                rodneCislo: this.state.rodneCislo,
                typDokladuTotoznosti: this.state.totoznost,
                cisloDokladuTotoznosti: this.state.cisloTotoznosti,
                vyskaVkladu: this.state.vyskaVkladu,
                podielSpolocnost: this.state.podielSpolocnost,
                rozsahSplatenia: this.state.rozsahSplatenia,
                konatel: this.state.konatel,
                spravcaVkladu: this.state.spravcaVkladu,
                registerTrestov: {
                    miestoNarodenia: this.state.miestoNarodenia,
                    statnaPrislusnost: this.state.statnaPrislusnost,
                    rodnePriezvisko: this.state.rodnePriezvisko,
                    predchadzajucePriezvisko: this.state.predchadzajucePriezvisko,
                    menoOtca: this.state.menoOtca,
                    priezviskoOtca: this.state.priezviskoOtca,
                    menoMatky: this.state.menoMatky,
                    priezviskoMatky: this.state.priezviskoMatky,
                    rodnePriezviskoMatky: this.state.rodnePriezviskoMatky
                }

            }

            var spolocnici = this.state.spolocnici;
            spolocnici.push(datas);



            this.setState({
                add: false,
                spolocnici: spolocnici,
            })


            event.preventDefault();
        }
        else{
            if(this.state.ico == ""){
                this.setState({
                    ico2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    ico2: "",
                })
            }

            if(this.state.obchodneMeno == ""){
                this.setState({
                    obchodneMeno2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    obchodneMeno2: "",
                })
            }
            if(this.state.ulicaFirma == ""){
                this.setState({
                    ulicaFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    ulicaFirma2: "",
                })
            }
            if(this.state.supisneCisloFirma == ""){
                this.setState({
                    supisneCisloFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    supisneCisloFirma2: "",
                })
            }
            if(this.state.obecFirma == ""){
                this.setState({
                    obecFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    obecFirma2: "",
                })
            }
            if(this.state.pscFirma == ""){
                this.setState({
                    pscFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    pscFirma2: "",
                })
            }
            if(this.state.statFirma == ""){
                this.setState({
                    statFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    statFirma2: "",
                })
            }

            if(this.state.menoKonatel == ""){
                this.setState({
                    menoKonatel2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    menoKonatel2: ""
                })
            }

            if(this.state.priezviskoKonatel == ""){
                this.setState({
                    priezviskoKonatel2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    priezviskoKonatel2: "",
                })
            }

            if(this.state.datumNarodeniaFirma == ""){
                this.setState({
                    datumNarodeniaFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    datumNarodeniaFirma2: "",
                })
            }

            if(this.state.rodneCisloFirma == ""){
                this.setState({
                    rodneCisloFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    rodneCisloFirma2: "",
                })
            }
            if(this.state.vyskaVkladuFirma == ""){
                this.setState({
                    vyskaVkladu2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    vyskaVkladu2: "",
                })
            }

            if(this.state.podielSpolocnostFirma == ""){
                this.setState({
                    podielSpolocnostFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    podielSpolocnostFirma2: "",
                })
            }

            if(this.state.rozsahSplateniaFirma == ""){
                this.setState({
                    rozsahSplatenieFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    rozsahSplatenieFirma2: "",
                })
            }

            var datas = {
                osoba: this.state.osoba,
                ico: this.state.ico,
                obchodneMeno: this.state.obchodneMeno,
                ulica: this.state.ulicaFirma,
                supisneCisloFirma: this.state.supisneCisloFirma,
                obec: this.state.obecFirma,
                psc: this.state.pscFirma,
                stat: this.state.statFirma,
                vyskaVkladuFirma: this.state.vyskaVkladuFirma,
                podielSpolocnostFirma: this.state.podielSpolocnostFirma,
                rozsahSplateniaFirma: this.state.rozsahSplateniaFirma,
                konatel: {
                    meno: this.state.menoKonatel,
                    priezvisko: this.state.priezviskoKonatel,
                    datumNarodenia: this.state.datumNarodeniaFirma,
                    rodneCislo: this.state.rodneCisloFirma,
                }

            }

            var spolocnici = this.state.spolocnici;
            spolocnici.push(datas);


            this.setState({
                add: false,
                spolocnici: spolocnici,
            })

            event.preventDefault();

        }


    }

    handleEmail(event){
        this.setState({
            email: event.target.value,
        })
    }


    handleOsoba(event){
        this.setState({
            osoba: event.target.value
        })
    }


    handleFirmaSubmit(event){
        if(this.state.companyForm != "" && this.state.companyName != "") {
            this.setState({
                companyValidator: "Obchodné meno je voľné",
            });
            event.preventDefault();
        }
        else{
            event.preventDefault();
            NotificationManager.warning(null,"Nevyplnili ste všetký polia",3000);
            return;
        }

    }

    handleFirmaName(event){
        this.setState({
            companyName: event.target.value,
        })
    }
    handleFirmaForm(event){
        this.setState({
            companyForm: event.target.value,
        })
    }







    //PRIDANEEEEEEE






    handlePridat(){
        this.setState({pridat: !this.state.pridat});
    }

    handleSubmitForm(event){
        event.preventDefault();



        this.props.thirdSubmit(this.state.zivnosti);

        localStorage.setItem("thirdSubmit", JSON.stringify(this.state.zivnosti));

        this.props.makeSecond();
        event.preventDefault();
        return;
    }

    viazane(){
        var concated = this.state.remeselneZivnosti.concat(this.state.viazaneZivnosti);

        concated = concated.concat(this.state.volneZivnosti);
        var finded = new Array();
        concated.forEach(function (e) {
            if (e.type == "Viazaná živnosť") {
                finded.push(e);
            }
        }.bind(this));
        this.setState({finded: finded});
    }
    remeselne(){
        var concated = this.state.remeselneZivnosti.concat(this.state.viazaneZivnosti);

        concated = concated.concat(this.state.volneZivnosti);
        var finded = new Array();
        concated.forEach(function (e) {
            if (e.type == "Remeselná živnosť") {
                finded.push(e);
            }
        }.bind(this));
        this.setState({finded: finded});
    }

    volne(){

        var concated = this.state.remeselneZivnosti.concat(this.state.viazaneZivnosti);

        concated = concated.concat(this.state.volneZivnosti);
        var finded = new Array();
        concated.forEach(function (e) {
            if (e.type == "1") {
                finded.push(e);
            }
        }.bind(this));
        this.setState({finded: finded});
    }

    compareString(event){


        this.setState({choose: false});
        if(!isNaN(event.target.value.replace(/\s+/g, ''))){

            this.setState({inputZivnost: event.target.value});
            var finded = new Array();

            var concated = this.state.remeselneZivnosti.concat(this.state.viazaneZivnosti);

            concated = concated.concat(this.state.volneZivnosti);


            concated.forEach(function (e) {
                if(e.type == "Remeselná živnosť"){
                    var x=0;
                    e.subname.forEach(function (ev) {
                        if(ev.number == event.target.value){
                            x=1;
                        }
                    }.bind(this));
                    if(x==1){

                        finded.push(e);
                    }
                }
                else {
                    if (event.target.value == e.number) {

                        finded.push(e);
                    }
                }
            }.bind(this));

            this.setState({finded: finded});

        }
        else {

            this.setState({inputZivnost: event.target.value});
            var finded = new Array();

            var concated = this.state.remeselneZivnosti.concat(this.state.viazaneZivnosti);

            concated = concated.concat(this.state.volneZivnosti);


            // concated.forEach(function (e) {
            //     if (stringSimilarity.compareTwoStrings(this.replaceDiacritics(e.name), event.target.value) > 0.25) {
            //          console.log(e);
            //         finded.push(e);
            //     }
            // }.bind(this));

            var finds = this.filterList(event.target.value, concated);

            finds.forEach(function (e) {
                finded.push(e);
            }.bind(this));

            this.setState({finded: finded});

        }

    }

    filterList(word, array){
        var updatedList = array;

        updatedList = updatedList.filter(function(item){

            return this.removeDiacritics(item.name).toLowerCase().search(
                this.removeDiacritics(word).toLowerCase()) !== -1;

        }.bind(this));



        return updatedList;
    }

    replaceDiacritics(str){

        var diacritics = [
            {char: 'A', base: /[\300-\306]/g},
            {char: 'a', base: /[\340-\346]/g},
            {char: 'E', base: /[\310-\313]/g},
            {char: 'e', base: /[\350-\353]/g},
            {char: 'I', base: /[\314-\317]/g},
            {char: 'i', base: /[\354-\357]/g},
            {char: 'O', base: /[\322-\330]/g},
            {char: 'o', base: /[\362-\370]/g},
            {char: 'U', base: /[\331-\334]/g},
            {char: 'u', base: /[\371-\374]/g},
            {char: 'N', base: /[\321]/g},
            {char: 'n', base: /[\361]/g},
            {char: 'C', base: /[\307]/g},
            {char: 'c', base: /[\347]/g}
        ]



        diacritics.forEach(function(letter){
            str = str.replace(letter.base, letter.char);
        });

        return str;
    };



    removeDiacritics(str) {
        if(!changes) {
            changes = defaultDiacriticsRemovalMap;
        }
        for(var i=0; i<changes.length; i++) {
            str = str.replace(changes[i].letters, changes[i].base);
        }
        return str;
    }

    componentDidMount(){
        window.scrollTo(0, 0);

        this.props.addlinka("one");

        var thirdSubmit = localStorage.getItem("thirdSubmit");


        if(thirdSubmit != null && thirdSubmit != "proccess")
            thirdSubmit = JSON.parse(thirdSubmit);


        if(thirdSubmit != null && thirdSubmit != "proccess"){

            this.setState({
                zivnosti: thirdSubmit,
            })

            var price = 0;

            thirdSubmit.forEach(function (e) {

                price = price+ e.price;
            });

            this.setState({
                price: price,
            })

            this.props.addZivnostCena(price);

        }
        else{
            localStorage.setItem("thirdSubmit", "proccess");
        }

        var array = new Array();
        csv()
            .fromStream(request.get('https://data.gov.sk/it/dataset/06607a41-d13a-4ae3-9fb2-32138c368aae/resource/29c5516b-c41e-468b-af9f-f1ee6382645f/download/zoznamremeselnychivnosti.csv'))
            .subscribe((json)=>{
                // console.log(json)
                var x = 0;
                array.forEach(function (e) {
                    if(e.name == json['názov remeselnej živnosti']){
                        e.subname.push({number: json['kód príslušného študijného odboru'], name: json["názov  príslušného študijného odboru"]});
                        x=1;
                    }

                }.bind(this));
                if(x==0) {
                    array.push({'name': json['názov remeselnej živnosti'],'type': "Remeselná živnosť", 'subname': [{number: json['kód príslušného študijného odboru'], name: json["názov  príslušného študijného odboru"]}]});
                }

            });

        var array2 = new Array();

        csv()
            .fromStream(request.get(' https://data.gov.sk/it/dataset/06607a41-d13a-4ae3-9fb2-32138c368aae/resource/69dc327c-469a-4214-9bad-e5fff7f63d66/download/zoznamviazanychivnosti.csv'))
            .subscribe((json)=>{



                var x = 0;
                array2.push({'name': json['NÁZOV VIAZANEJ ŽIVNOSTI'],'type': "Viazaná živnosť", subname: json['PREUKAZ ODBORNEJ SPôSOBILOSTI'] });

            });





        this.setState({remeselneZivnosti: array})
        this.setState({viazaneZivnosti: array2})


        var array3 = new Array();

        var self = this;

        axios.get("https://www.mojekonto.sk/api/zivnosti").then(res=> {
            self.setState({volneZivnosti: res.data});
        });



        var secondSubmit = localStorage.getItem("secondSubmit");

        if(secondSubmit != null && secondSubmit != 'proccess') {



            secondSubmit = JSON.parse(secondSubmit);
            console.log(secondSubmit);

            var osoby = this.state.osoby;

            var spolocnici = secondSubmit.spolocnici;

            var thirdSubmit = localStorage.getItem("thirdSubmit");


            if(thirdSubmit != null && thirdSubmit != 'proccess') {
                thirdSubmit = JSON.parse(thirdSubmit);
                thirdSubmit.forEach(function (e) {

                    if(e.type == 'Remeselná živnosť' || e.type == 'Viazaná živnosť') {


                        if (e.zastupca != 'undefined') {

                            var allowed = 1;
                            var zastupca = e.zastupca;
                            spolocnici.forEach(function (eve) {


                                if (eve.rodneCislo == zastupca.rodneCislo) {
                                    allowed = 0;
                                }
                            }.bind(this));
                        }
                    }

                    if(e.zastupca != 'undefined' && allowed == 1){

                        // if(e.zastupca.konatel == true) {

                        var datas = {
                            titul: e.zastupca.titul,
                            meno: e.zastupca.meno,
                            priezvisko: e.zastupca.priezvisko,
                            titul2: e.zastupca.titul2,
                            ulica: e.zastupca.ulica,
                            supCislo: e.zastupca.supCislo,
                            obec: e.zastupca.obec,
                            psc: e.zastupca.psc,
                            stat: e.zastupca.stat,
                            datumNarodenia: e.zastupca.datumNarodenia,
                            rodneCislo: e.zastupca.rodneCislo,
                            totoznost: e.zastupca.typDokladuTotoznosti,
                            cisloDokladu: e.zastupca.cisloDokladuTotoznosti,
                            registerTrestov: {
                                miestoNarodenia: e.zastupca.registerTrestov.miestoNarodenia,
                                statnaPrislusnost: e.zastupca.registerTrestov.statnaPrislusnost,
                                rodnePriezvisko: e.zastupca.registerTrestov.rodnePriezvisko,
                                predchadzajucePriezvisko: e.zastupca.registerTrestov.predchadzajucePriezvisko,
                                menoOtca: e.zastupca.registerTrestov.menoOtca,
                                priezviskoOtca: e.zastupca.registerTrestov.priezviskoOtca,
                                menoMatky: e.zastupca.registerTrestov.menoMatky,
                                priezviskoMatky: e.zastupca.registerTrestov.priezviskoMatky,
                                rodnePriezviskoMatky: e.zastupca.registerTrestov.rodnePriezviskoMatky
                            }
                        }
                        osoby.push(datas);
                        this.setState({osoby: osoby})
                        //   }


                    }
                }.bind(this));
            }


            if (Array.isArray(spolocnici)) {
                spolocnici.forEach(function (e) {

                    if (e.osoba == 'fyzicka') {
                        if(e.konatel == true) {
                            console.log(e);

                            var datas = {
                                titul: e.titul,
                                meno: e.meno,
                                priezvisko: e.priezvisko,
                                titul2: e.titul2,
                                ulica: e.ulica,
                                supCislo: e.supCislo,
                                obec: e.obec,
                                psc: e.psc,
                                totoznost: e.typDokladuTotoznosti,
                                cisloTotoznosti: e.cisloDokladuTotoznosti,
                                stat: e.stat,
                                datumNarodenia: e.datumNarodenia,
                                rodneCislo: e.rodneCislo,
                                registerTrestov: {
                                    miestoNarodenia: e.registerTrestov.miestoNarodenia,
                                    statnaPrislusnost: e.registerTrestov.statnaPrislusnost,
                                    rodnePriezvisko: e.registerTrestov.rodnePriezvisko,
                                    predchadzajucePriezvisko: e.registerTrestov.predchadzajucePriezvisko,
                                    menoOtca: e.registerTrestov.menoOtca,
                                    priezviskoOtca: e.registerTrestov.priezviskoOtca,
                                    menoMatky: e.registerTrestov.menoMatky,
                                    priezviskoMatky: e.registerTrestov.priezviskoMatky,
                                    rodnePriezviskoMatky: e.registerTrestov.rodnePriezviskoMatky
                                }
                            }
                            osoby.push(datas);
                            console.log(datas);
                            this.setState({osoby: osoby})
                        }



                    }
                }.bind(this));

            }
        }
        else{
            var spolocnici = this.props.spolocnici;

            var osoby = this.state.osoby;

            if(Array.isArray(spolocnici)) {
                spolocnici.forEach(function (e) {
                    if (e.osoba == 'fyzicka') {
                        if(e.osoba.konatel == true) {
                            var datas = {
                                titul: e.titul,
                                meno: e.meno,
                                priezvisko: e.priezvisko,
                                titul2: e.titul2,
                                ulica: e.ulica,
                                supCislo: e.supCislo,
                                obec: e.obec,
                                psc: e.psc,
                                stat: e.stat,
                                totoznost: e.typDokladuTotoznosti,
                                cisloTotoznosti: e.cisloDokladuTotoznosti,
                                datumNarodenia: e.datumNarodenia,
                                rodneCislo: e.rodneCislo,
                                registerTrestov: {
                                    miestoNarodenia: e.registerTrestov.miestoNarodenia,
                                    statnaPrislusnost: e.registerTrestov.statnaPrislusnost,
                                    rodnePriezvisko: e.registerTrestov.rodnePriezvisko,
                                    predchadzajucePriezvisko: e.registerTrestov.predchadzajucePriezvisko,
                                    menoOtca: e.registerTrestov.menoOtca,
                                    priezviskoOtca: e.registerTrestov.priezviskoOtca,
                                    menoMatky: e.registerTrestov.menoMatky,
                                    priezviskoMatky: e.registerTrestov.priezviskoMatky,
                                    rodnePriezviskoMatky: e.registerTrestov.rodnePriezviskoMatky
                                }
                            }
                        }
                        else{
                            var datas = {
                                titul: e.titul,
                                meno: e.meno,
                                priezvisko: e.priezvisko,
                                titul2: e.titul2,
                                ulica: e.ulica,
                                supCislo: e.supCislo,
                                obec: e.obec,
                                totoznost: e.typDokladuTotoznosti,
                                cisloTotoznosti: e.cisloDokladuTotoznosti,
                                psc: e.psc,
                                stat: e.stat,
                                datumNarodenia: e.datumNarodenia,
                                rodneCislo: e.rodneCislo,

                            }
                        }
                        osoby.push(datas);
                        this.setState({osoby: osoby})
                    }
                }.bind(this));
            }
        }


    }



    minuscount(pricik){
        var price1 = this.state.price;

        price1 = price1 - pricik;

        this.setState({price: price1});

        this.props.addZivnostCena(price1);
    }

    delete = (index) => {
        var zivnosti = this.state.zivnosti;
        var id = 0
        zivnosti.forEach(function (e) {
            if(index == id){
                this.minuscount(e.price);
            }
            id++;
        }.bind(this));


        zivnosti.splice(index, 1);



        this.setState({zivnosti: zivnosti});

    }

    handleZivnost(event){
        this.setState({actual: event.target.value});
    }


    addSelection(name, price, type){
        var zivnosti = this.state.zivnosti;

        var x = 0;
        zivnosti.forEach(function (e) {
            if(e.name == name){
                NotificationManager.error("","Predmet podnikania už je pridaný", 3000);
                x = 1;
                return;
            }
        }.bind(this));

        if(x==1){
            return;
        }
        if(this.state.prevadzkaren == true) {

            if(this.state.prevulica == ""){
                this.setState({prevulica2: "Toto pole je povinné!"})
            }
            else{
                this.setState({prevulica2: ""})
            }

            if(this.state.prevcislo == ""){
                this.setState({prevcislo2: "Toto pole je povinné!"})
            }
            else{
                this.setState({prevcislo2: ""})
            }
            if(this.state.prevobec == ""){
                this.setState({prevobec2: "Toto pole je povinné!"})
            }
            else{
                this.setState({prevobec2: ""})
            }
            if(this.state.prevpsc == ""){
                this.setState({prevpsc2: "Toto pole je povinné!"})
            }
            else{
                this.setState({prevpsc2: ""})
            }

            if(this.state.prevulica == "" || this.state.prevcislo == "" || this.state.prevobec == "" || this.state.prevpsc == ""){
                NotificationManager.warning("", "Nevyplnili ste všetky polia!", 3000);

                return;
            }

            var prevadzkaren = {
                ulica: this.state.prevulica,
                cislo: this.state.prevcislo,
                obec: this.state.prevobec,
                psc: this.state.prevpsc,
            }

        }


        this.setState({
            prevulica: "",
            prevcislo: "",
            prevobec: "",
            prevpsc: "",

        });

        var zastupca;


        var osoby = this.state.osoby;
        var x = 0;
        osoby.forEach(function (e) {
            if(x == this.state.actualzastupca){
                zastupca = e;
                console.log(zastupca);
            }
            x++;
        }.bind(this));



        if(type == "Voľná živnosť"){
            zivnosti.push({name:name, price: price, type: type});
        }
        else{
            if(prevadzkaren != null) {
                zivnosti.push({name: name, price: price, type: type, prevadzkaren: prevadzkaren, zastupca: zastupca, osvedcenie: this.state.osvedcenie});
            }

            else{
                zivnosti.push({name: name, price: price, type: type, zastupca: zastupca, osvedcenie: this.state.osvedcenie});
            }
        }


        this.setState({
            osvedcenie: new Array(),
        })

        this.setState({zivnosti: zivnosti});
        this.setState({actual: ""});
        this.setState({finded: this.state.finded});
        this.setState({inputZivnost: this.state.inputZivnost})
        var price1 = this.state.price;
        price1 = this.state.price + price;
        this.setState({price: price1});
        this.props.addZivnostCena(price1);
        this.setState({
            prevadzkaren: false,
        })
        NotificationManager.info("", "Predmet podnikania bol úspešné pridaný!", 3000)
    }

    render() {
        if (Array.isArray(this.state.remeselneZivnosti)) {
            return (
                <div className="companyThird">
                    <NotificationContainer/>
                    <div className="container">
                        <div className="row ods">
                            <div className="col-md-12">
                                <h2 className="center">VYBERTE SI PREDMET PODNIKANIA</h2>
                                <p className="center">Na tomto mieste vám pomôžeme s výberom najvhodnejších predmetov
                                    podnikania.<br/>
                                    Ako prvú zadajte hlavnú činnosť podnikania</p>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-12">

                                <h3 className="nadpis">Alebo vyberte zo zoznamu</h3>
                                {this.state.choose == false ?
                                    <a onClick={this.handleChoose} className="btn btn-primary whitecolor" >Vybrať</a>
                                    :null}

                                {this.state.choose == true ?

                                    <div>
                                        <br/>
                                        <div className="centerize">
                                            <h4>Zoznam živnostenských predmetov</h4>
                                            <p>Oficiálny zoznam ministerstva vnútra</p>
                                            <img src={Erb}/><br/><br/>

                                            <a className="btn btn-primary but whitecolor"   onClick={this.volne}>Voľné živností</a>
                                            <a className="btn btn-primary but whitecolor"  onClick={this.viazane}>Viazané živností</a>
                                            <a className="btn btn-primary but whitecolor"   onClick={this.remeselne}>Remeselné živností</a>
                                        </div>
                                    </div>

                                    : null }




                            </div>
                            <div className="col-md-12">

                            </div>
                        </div>
                    </div>
                    <div className="container-fluid blue nomargt">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="npad">
                                        {this.state.finded != "" ?
                                            <div className="row">
                                                {this.state.finded.map(data=> {
                                                        if(data.type=="Remeselná živnosť"){ return(
                                                            <Collapsible trigger={`${data.name} | ${data.type}`}>
                                                                {data.subname.map(child =>

                                                                    <p> {child.number} {child.name} {child.type}</p>



                                                                )}
                                                                <br/>
                                                                <label className="label_imagem_artigo"> Odborná spôsobilosť: (slúži pre urýchlenie procesu) </label>
                                                                <br/>
                                                                <input className="input_imagem_artigo whitesub" type="file"  onChange={this.onChange} />
                                                                <br/><br/>

                                                                { this.state.osvedcenie.map((data,index) =>
                                                                    <ul>
                                                                        <li>{data.path} &nbsp; <a onClick={()=>this.delosvedcenie(index)}><img width="15" src={Delete}/></a></li>
                                                                    </ul>

                                                                )

                                                                }

                                                                <br/><br/>

                                                                <p>Zodpovedný zástupca: </p>
                                                                <div className="form-row">
                                                                    <div className="col-md-6">
                                                                        <select className="form-control">
                                                                            {this.state.osoby.map(datas =>
                                                                                <option>{datas.meno} {datas.priezvisko}</option>
                                                                            )}

                                                                        </select>
                                                                        <br/>
                                                                    </div>
                                                                    

                                                                </div>

                                                                {this.state.pridat == true ?
                                                                    <div className="fyzickaosoba">

                                                                        <div className="form-row firmazvol">
                                                                            <div className="col-md-2">
                                                                                <br/>
                                                                                <label>Titul pred menom</label>
                                                                                <input value={this.state.titulPredMenom} onChange={this.handleTitulPredMenom} type="text" className="form-control" />
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <br/><label>Meno *</label>
                                                                                <input value={this.state.meno} onChange={this.handleMeno} type="text" className="form-control" />
                                                                                <span className="error">{this.state.meno2}</span>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <br/><label>Priezvisko *</label>
                                                                                <input value={this.state.priezvisko} onChange={this.handlePriezvisko} type="text" className="form-control" />
                                                                                <span className="error">{this.state.priezvisko2}</span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <br/><label>Titul za menom</label>
                                                                                <input onChange={this.handleTitulZaMenom} value={this.state.titulZaMenom} type="text" className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                        <br/>
                                                                        <div className="form-row firmazvol">
                                                                            <div className="col-md-8">
                                                                                <label>Ulica/Námestie *</label>
                                                                                <input data-tip="Zadajte názov ulice alebo verejného priestranstva (námestie). Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce." onChange={this.handleUlica} value={this.state.ulica} type="text" className="form-control" />
                                                                                <span className="error">{this.state.ulica2}</span>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label>Súpisné číslo / orientačné číslo *</label>
                                                                                <input data-tip="Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz)." type="text" value={this.state.supCislo} onChange={this.handleSupCislo} className="form-control" />
                                                                                <span className="error">{this.state.supCislo2}</span>
                                                                            </div>

                                                                        </div>
                                                                        <br/>
                                                                        <div className="form-row firmazvol">
                                                                            <div className="col-md-8">
                                                                                <label>Obec *</label>
                                                                                <input value={this.state.obec} onChange={this.handleObec} type="text" className="form-control" />
                                                                                <span className="error">{this.state.obec2}</span>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label>PSČ *</label>
                                                                                <input value={this.state.psc} onChange={this.handlePsc} type="text" className="form-control" />
                                                                                <span className="error">{this.state.psc2}</span>
                                                                            </div>

                                                                        </div>
                                                                        <br/>
                                                                        <div className="form-row firmazvol">
                                                                            <div className="col-md-4">
                                                                                <label>Štát *</label>
                                                                                <input value={this.state.stat} onChange={this.handleStat} type="text" className="form-control" />
                                                                                <span className="error">{this.state.stat2}</span>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label>Dátum narodenia *</label>
                                                                                <input value={this.state.datumNarodenie} placeholder="dd.mm.YYYY" onChange={this.handleDatumNarodenia} type="text" className="form-control" />
                                                                                <span className="error">{this.state.datumNarodenie2}</span>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label>Rodné číslo *</label>
                                                                                <input value={this.state.rodneCislo} onChange={this.handleRodneCislo} type="text" className="form-control" />
                                                                                <span className="error">{this.state.rodneCislo2}</span>
                                                                            </div>

                                                                        </div>
                                                                        <div className="form-row firmazvol">
                                                                            <div className="col-md-4">
                                                                                <br/>
                                                                                <label>Typ dokladu totožnosti *</label>
                                                                                <select onChange={this.handleTotoznost} className="form-control">
                                                                                    <option>Vyberte</option>
                                                                                    <option>Občiansky preukaz</option>
                                                                                    <option>Pas</option>
                                                                                    <option>Iný doklad</option>
                                                                                </select>
                                                                                <span className="error">{this.state.totoznost2}</span>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <br/>
                                                                                <label>Číslo dokladu totožnosti *</label>
                                                                                <input type="text" value={this.state.cisloTotoznosti} onChange={this.handleCisloTotoznosti} className="form-control" />
                                                                                <span className="error">{this.state.cisloTotoznosti2}</span>
                                                                            </div>
                                                                        </div>
                                                                        <br/>

                                                                        <br/>



                                                                        <br/>
                                                                        <br/>

                                                                        <div>
                                                                            <h4 className="nadpis"> Údaje pre vypis z registra trestov</h4>
                                                                            <br/>
                                                                            Aby mohol za vás živnostenský úrad online vyžiadať výpis z registra trestov konateľa, vyžaduje nasledovné údaje:
                                                                            <br/>
                                                                            <br/>

                                                                            <div className="registerTrestov">

                                                                                <div className="form-row firmazvol">
                                                                                    <div className="col-md-4">
                                                                                        <label>Miesto narodenia *</label>
                                                                                        <input type="text" className="form-control" value={this.state.miestoNarodenia} onChange={this.handleMiestoNarodenia} />
                                                                                        <span className="error">{this.state.miestoNarodenia2}</span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <label>Štátna príslušnosť *</label>
                                                                                        <input type="text" className="form-control" value={this.state.statnaPrislusnost} onChange={this.handleStatnaPrislusnost} />
                                                                                        <span className="error">{this.state.statnaPrislusnost2}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <br/>
                                                                                <div className="form-row firmazvol">

                                                                                    <div className="col-md-4">
                                                                                        <label>Rodné priezvisko *</label>
                                                                                        <input type="text" className="form-control" value={this.state.rodnePriezvisko} onChange={this.handleRodnePriezvisko} />
                                                                                        <span className="error">{this.state.rodnePriezvisko2}</span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <label>Predchádzajúce priezvisko</label>
                                                                                        <input type="text" className="form-control" value={this.state.predchadzajucePriezvisko} onChange={this.handlePredchadzajucePriezvisko} />
                                                                                        <span className="error">{this.state.predchadzajucePriezvisko2}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <br/>
                                                                                <div className="form-row firmazvol">

                                                                                    <div className="col-md-4">
                                                                                        <label>Meno otca *</label>
                                                                                        <input type="text" className="form-control" value={this.state.menoOtca} onChange={this.handleMenoOtca} />
                                                                                        <span className="error">{this.state.menoOtca2}</span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <label>Priezvisko otca *</label>
                                                                                        <input type="text" className="form-control" value={this.state.priezviskoOtca} onChange={this.handlePriezviskoOtca} />
                                                                                        <span className="error">{this.state.priezviskoOtca2}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <br/>
                                                                                <div className="form-row firmazvol">

                                                                                    <div className="col-md-4">
                                                                                        <label>Meno matky *</label>
                                                                                        <input type="text" className="form-control" value={this.state.menoMatky} onChange={this.handleMenoMatky} />
                                                                                        <span className="error">{this.state.menoMatky2}</span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <label>Priezvisko matky *</label>
                                                                                        <input type="text" className="form-control" value={this.state.priezviskoMatky} onChange={this.handlePriezviskoMatky} />
                                                                                        <span className="error">{this.state.priezviskoMatky2}</span>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <label>Rodné priezvisko matky *</label>
                                                                                        <input type="text" className="form-control" value={this.state.rodnePriezviskoMatky} onChange={this.handleRodnePriezviskoMatky} />
                                                                                        <span className="error">{this.state.rodnePriezviskoMatky2}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-row">
                                                                                    <div className="col-md-12">
                                                                                        <br/><br/>
                                                                                        <button onClick={this.handlePridatZastupcu} className="btn btn-primary">Pridať zástupcu</button>
                                                                                        <br/> <br/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    : null }

                                                                <div className="form-row">
                                                                    <div className="col-12">
                                                                        <label for="prevadzkaren">Prevadzkáreň </label><br/>

                                                                        <input type="radio" onChange={this.prevadzkaren} name="prevadzkaren" value="0" /> Nie<br/>
                                                                        <input type="radio" onChange={this.prevadzkaren} name="prevadzkaren" value="1" /> Áno (uvádza sa, ak chcete vykonávať živnosti na inom mieste ako je sídlo)<br/>

                                                                    </div>
                                                                </div>
                                                                <br/>

                                                                {this.state.prevadzkaren == true ?
                                                                    <div className="form-row firmazvol">
                                                                        <br/><br/> <br/><br/>
                                                                        <div className="col-md-6">
                                                                            <label>ULICA/NÁMESTIE *</label>
                                                                            <input type="text" onChange={this.handlePrevUlica} value={this.state.prevulica} className="form-control" />
                                                                            <span className="error">{this.state.prevulica2}</span>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label>SÚPISNÉ ČÍSLO / ORIENTAČNÉ ČÍSLO *</label>
                                                                            <input type="text" onChange={this.handlePrevCislo} value={this.state.prevcislo} className="form-control" />
                                                                            <span className="error">{this.state.prevcislo2}</span>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label>OBEC *</label>
                                                                            <input type="text" value={this.state.prevobec} onChange={this.handlePrevObec} className="form-control" />
                                                                            <span className="error">{this.state.prevobec2}</span>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label>PSČ *</label>
                                                                            <input type="text" value={this.state.prevpsc} onChange={this.handlePrevPsc} className="form-control" />
                                                                            <span className="error">{this.state.prevpsc2}</span>
                                                                        </div>

                                                                        <br/><br/> <br/><br/>
                                                                    </div>

                                                                    : null}

                                                                <br/>
                                                                <a  onClick={() => this.addSelection(data.name, 7.50, data.type)}
                                                                    className="btn btn-primary whitecolor">Pridať</a> <span
                                                                className="price">Cena 7,50€</span>


                                                            </Collapsible>
                                                        );}
                                                        else if(data.type == "Viazaná živnosť"){
                                                            return(
                                                                <Collapsible trigger={`${data.name} | ${data.type}`}>
                                                                    <p>Osvedčenie: {data.subname}</p>


                                                                    <label className="label_imagem_artigo"> Odborná spôsobilosť: (slúži pre urýchlenie procesu) </label>
                                                                    <br/>

                                                                    <input className="input_imagem_artigo whitesub" type="file"  onChange={this.onChange} />
                                                                    <br/><br/>

                                                                    { this.state.osvedcenie.map((data,index) =>
                                                                        <ul>
                                                                            <li>{data.path} &nbsp; <a onClick={()=>this.delosvedcenie(index)}><img width="15" src={Delete}/></a></li>
                                                                        </ul>

                                                                    )

                                                                    }

                                                                    <br/><br/>


                                                                    <p>Zodpovedný zástupca: </p>
                                                                    <div className="form-row">
                                                                        <div className="col-md-6">
                                                                            <select onChange={this.handleZastupca} className="form-control">
                                                                                {this.state.osoby.map((datas,index) =>
                                                                                    <option value={index}> {datas.meno} {datas.priezvisko}</option>
                                                                                )}

                                                                            </select>
                                                                            <br/>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <button onClick={this.handlePridat} className="btn btn-primary">Pridať ďalšieho</button>
                                                                        </div>

                                                                    </div>

                                                                    {this.state.pridat == true ?
                                                                        <div className="fyzickaosoba">

                                                                            <div className="form-row firmazvol">
                                                                                <div className="col-md-2">
                                                                                    <br/>
                                                                                    <label>Titul pred menom</label>
                                                                                    <input value={this.state.titulPredMenom} onChange={this.handleTitulPredMenom} type="text" className="form-control" />
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <br/><label>Meno *</label>
                                                                                    <input value={this.state.meno} onChange={this.handleMeno} type="text" className="form-control" />
                                                                                    <span className="error">{this.state.meno2}</span>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <br/><label>Priezvisko *</label>
                                                                                    <input value={this.state.priezvisko} onChange={this.handlePriezvisko} type="text" className="form-control" />
                                                                                    <span className="error">{this.state.priezvisko2}</span>
                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    <br/><label>Titul za menom</label>
                                                                                    <input onChange={this.handleTitulZaMenom} value={this.state.titulZaMenom} type="text" className="form-control" />
                                                                                </div>
                                                                            </div>
                                                                            <br/>
                                                                            <div className="form-row firmazvol">
                                                                                <div className="col-md-8">
                                                                                    <label>Ulica/Námestie *</label>
                                                                                    <input data-tip="Zadajte názov ulice alebo verejného priestranstva (námestie). Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce." onChange={this.handleUlica} value={this.state.ulica} type="text" className="form-control" />
                                                                                    <span className="error">{this.state.ulica2}</span>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <label>Súpisné číslo / orientačné číslo *</label>
                                                                                    <input data-tip="Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz)." type="text" value={this.state.supCislo} onChange={this.handleSupCislo} className="form-control" />
                                                                                    <span className="error">{this.state.supCislo2}</span>
                                                                                </div>

                                                                            </div>
                                                                            <br/>
                                                                            <div className="form-row firmazvol">
                                                                                <div className="col-md-8">
                                                                                    <label>Obec *</label>
                                                                                    <input value={this.state.obec} onChange={this.handleObec} type="text" className="form-control" />
                                                                                    <span className="error">{this.state.obec2}</span>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <label>PSČ *</label>
                                                                                    <input value={this.state.psc} onChange={this.handlePsc} type="text" className="form-control" />
                                                                                    <span className="error">{this.state.psc2}</span>
                                                                                </div>

                                                                            </div>
                                                                            <br/>
                                                                            <div className="form-row firmazvol">
                                                                                <div className="col-md-4">
                                                                                    <label>Štát *</label>
                                                                                    <input value={this.state.stat} onChange={this.handleStat} type="text" className="form-control" />
                                                                                    <span className="error">{this.state.stat2}</span>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <label>Dátum narodenia *</label>
                                                                                    <input value={this.state.datumNarodenie} placeholder="dd.mm.YYYY" onChange={this.handleDatumNarodenia} type="text" className="form-control" />
                                                                                    <span className="error">{this.state.datumNarodenie2}</span>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <label>Rodné číslo *</label>
                                                                                    <input value={this.state.rodneCislo} onChange={this.handleRodneCislo} type="text" className="form-control" />
                                                                                    <span className="error">{this.state.rodneCislo2}</span>
                                                                                </div>

                                                                            </div>
                                                                            <div className="form-row firmazvol">
                                                                                <div className="col-md-4">
                                                                                    <br/>
                                                                                    <label>Typ dokladu totožnosti *</label>
                                                                                    <select onChange={this.handleTotoznost} className="form-control">
                                                                                        <option>Vyberte</option>
                                                                                        <option>Občiansky preukaz</option>
                                                                                        <option>Pas</option>
                                                                                        <option>Iný doklad</option>
                                                                                    </select>
                                                                                    <span className="error">{this.state.totoznost2}</span>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <br/>
                                                                                    <label>Číslo dokladu totožnosti *</label>
                                                                                    <input type="text" value={this.state.cisloTotoznosti} onChange={this.handleCisloTotoznosti} className="form-control" />
                                                                                    <span className="error">{this.state.cisloTotoznosti2}</span>
                                                                                </div>
                                                                            </div>
                                                                            <br/>

                                                                            <br/>



                                                                            <br/>
                                                                            <br/>

                                                                            <div>
                                                                                <h4 className="nadpis"> Údaje pre vypis z registra trestov</h4>
                                                                                <br/>
                                                                                Aby mohol za vás živnostenský úrad online vyžiadať výpis z registra trestov konateľa, vyžaduje nasledovné údaje:
                                                                                <br/>
                                                                                <br/>

                                                                                <div className="registerTrestov">

                                                                                    <div className="form-row firmazvol">
                                                                                        <div className="col-md-6">
                                                                                            <label>Miesto narodenia *</label>
                                                                                            <input type="text" className="form-control" value={this.state.miestoNarodenia} onChange={this.handleMiestoNarodenia} />
                                                                                            <span className="error">{this.state.miestoNarodenia2}</span>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <label>Štátna príslušnosť *</label>
                                                                                            <input type="text" className="form-control" value={this.state.statnaPrislusnost} onChange={this.handleStatnaPrislusnost} />
                                                                                            <span className="error">{this.state.statnaPrislusnost2}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br/>
                                                                                    <div className="form-row firmazvol">

                                                                                        <div className="col-md-6">
                                                                                            <label>Rodné priezvisko *</label>
                                                                                            <input type="text" className="form-control" value={this.state.rodnePriezvisko} onChange={this.handleRodnePriezvisko} />
                                                                                            <span className="error">{this.state.rodnePriezvisko2}</span>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <label>Predchádzajúce priezvisko</label>
                                                                                            <input type="text" className="form-control" value={this.state.predchadzajucePriezvisko} onChange={this.handlePredchadzajucePriezvisko} />
                                                                                            <span className="error">{this.state.predchadzajucePriezvisko2}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br/>
                                                                                    <div className="form-row firmazvol">

                                                                                        <div className="col-md-6">
                                                                                            <label>Meno otca *</label>
                                                                                            <input type="text" className="form-control" value={this.state.menoOtca} onChange={this.handleMenoOtca} />
                                                                                            <span className="error">{this.state.menoOtca2}</span>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <label>Priezvisko otca *</label>
                                                                                            <input type="text" className="form-control" value={this.state.priezviskoOtca} onChange={this.handlePriezviskoOtca} />
                                                                                            <span className="error">{this.state.priezviskoOtca2}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br/>
                                                                                    <div className="form-row firmazvol">

                                                                                        <div className="col-4">
                                                                                            <label>Meno matky *</label>
                                                                                            <input type="text" className="form-control" value={this.state.menoMatky} onChange={this.handleMenoMatky} />
                                                                                            <span className="error">{this.state.menoMatky2}</span>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <label>Priezvisko matky *</label>
                                                                                            <input type="text" className="form-control" value={this.state.priezviskoMatky} onChange={this.handlePriezviskoMatky} />
                                                                                            <span className="error">{this.state.priezviskoMatky2}</span>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <label>Rodné priezvisko matky *</label>
                                                                                            <input type="text" className="form-control" value={this.state.rodnePriezviskoMatky} onChange={this.handleRodnePriezviskoMatky} />
                                                                                            <span className="error">{this.state.rodnePriezviskoMatky2}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form-row">
                                                                                        <div className="col-md-12">
                                                                                            <br/><br/>
                                                                                            <button onClick={this.handlePridatZastupcu} className="btn btn-primary">Pridať zástupcu</button>
                                                                                            <br/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        : null }

                                                                    <div className="form-row">
                                                                        <div className="col-12">
                                                                            <label for="prevadzkaren">Prevadzkáreň </label><br/>

                                                                            <input type="radio" onChange={this.prevadzkaren} name="prevadzkaren" value="0" /> Nie<br/>
                                                                            <input type="radio" onChange={this.prevadzkaren} name="prevadzkaren" value="1" /> Áno (uvádza sa, ak chcete vykonávať živnosti na inom mieste ako je sídlo)<br/>

                                                                        </div>
                                                                    </div>

                                                                    {this.state.prevadzkaren == true ?
                                                                        <div className="form-row firmazvol">
                                                                            <br/><br/>
                                                                            <div className="col-6">
                                                                                <label>ULICA/NÁMESTIE *</label>
                                                                                <input type="text" onChange={this.handlePrevUlica} value={this.state.prevulica} className="form-control" />
                                                                                <span className="error">{this.state.prevulica2}</span>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <label>SÚPISNÉ ČÍSLO / ORIENTAČNÉ ČÍSLO *</label>
                                                                                <input type="text" onChange={this.handlePrevCislo} value={this.state.prevcislo} className="form-control" />
                                                                                <span className="error">{this.state.prevcislo2}</span>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <label>OBEC *</label>
                                                                                <input type="text" value={this.state.prevobec} onChange={this.handlePrevObec} className="form-control" />
                                                                                <span className="error">{this.state.prevobec2}</span>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <label>PSČ *</label>
                                                                                <input type="text" value={this.state.prevpsc} onChange={this.handlePrevPsc} className="form-control" />
                                                                                <span className="error">{this.state.prevpsc2}</span>
                                                                            </div>


                                                                        </div>

                                                                        : null}

                                                                    <a   onClick={() => this.addSelection(data.name, 7.50, data.type)}



                                                                         className="btn btn-primary whitecolor">Pridať</a> <span
                                                                    className="price">Cena 7,50€</span>

                                                                </Collapsible>
                                                            );
                                                        }
                                                        else if(data.type == 1){
                                                            return(
                                                                <Collapsible triggerSibling={() => <a  onClick={() => this.addSelection(data.name, 0, "Voľná živnosť")}
                                                                                                       className="btn btn-primary whitecolor mensie">Pridať</a>}  trigger={`${data.name} | Voľná živnosť`}>

                                                                    {data.detail.map(child =>

                                                                        <p>{child.name} </p>

                                                                    )}



                                                                    <a  onClick={() => this.addSelection(data.name, 0, "Voľná živnosť")}
                                                                        className="btn btn-primary whitecolor">Pridať</a>

                                                                </Collapsible>
                                                            )
                                                        }
                                                    }

                                                )}

                                            </div>
                                            : null}
                                    </ul>
                                    <ReactTooltip/>
                                    <form onSubmit={this.handleSubmit}>
                                        <h3 className="nadpis">Predmety podnikania</h3><br/>
                                        <div class="form-row firmazvol">


                                            <div className="col-12 firmazvol">
                                                <input placeholder="Začnite písať" type="text" onChange={this.compareString} className="form-control" value={this.state.inputZivnost} />
                                            </div>

                                        </div>
                                    </form>
                                    <br/>


                                    {this.state.zivnosti.length > 0 ?
                                        <div>
                                            <h4 className={'nadpis'}>Zvolené predmety podnikania - {this.state.price}€</h4>
                                            <ul className="predmetyPodnikania">
                                                {this.state.zivnosti.map((item, index) =>
                                                    <li key={index}>
                                                        {item.name} <a onClick={() => this.delete(index)}><img className="odleft" width="15" src={Delete}/> </a></li>
                                                )}
                                            </ul>
                                        </div>
                                        : <h4 className={'nadpis'}>Žiadne predmety podnikania</h4>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={this.handleSubmitForm}>
                        <div className="container">
                            <div className="row ods odsbot">
                                <div className="col-md-12 centerize firmazvol">
                                    <input type="submit" className="btn btn-primary" value="Pokračovať" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            );
        }
        else{
            return(
                <div>Nacitavam</div>
            )
        }
    }

}

export default Zivnosti;