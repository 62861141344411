import React, { Component } from 'react';
import logo from './logo.svg';
import axios from 'axios';
import './App.css';

import Logo from "./logo_firmy.svg";
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';
import Zivnosti from "./Zivnosti";
import Udaje from "./Udaje";
import CompanyReview from "./CompanyReview";
import CompanyFinish from "./CompanyFinish";
ReactGA.initialize('UA-135032496-1');
ReactGA.pageview(window.location.pathname + window.location.search);




class App extends Component {

    constructor(props){
        super(props);
        this.state = {
            first: false,
            second: true,
            third: false,
            fourth: false,
            fifth: false,
            firstSubmit: "",
            secondSubmit: "",
            thirdSubmit: "",
            fourthSubmit: "",
            price: 25,
            doplnkove: new Array(),
            zivnostiCena: 0,
            show: true,
            linka: "",
            allowrender: false,
        }








        this.makeFirst = this.makeFirst.bind(this);
        this.makeSecond = this.makeSecond.bind(this);
        this.makeThird = this.makeThird.bind(this);
        this.makeFourth = this.makeFourth.bind(this);
        this.firstSubmit = this.firstSubmit.bind(this);
        this.fourthSubmit = this.fourthSubmit.bind(this);
        this.makeFifth = this.makeFifth.bind(this);
        this.thirdSubmit = this.thirdSubmit.bind(this);
        this.secondSubmit= this.secondSubmit.bind(this);
        this.addDoplnkove=this.addDoplnkove.bind(this);
        this.addZivnostCena = this.addZivnostCena.bind(this);
        this.closeCena = this.closeCena.bind(this);
        this.showCena = this.showCena.bind(this);
        this.one = this.one.bind(this);
        this.two = this.two.bind(this);
        this.three = this.three.bind(this);
        this.four = this.four.bind(this);
        this.addlinka = this.addlinka.bind(this);

    }


    addlinka(val){
        this.setState({
            linka: val,
        })
    }
    componentDidMount(){
        window.scrollTo(0, 0);
        if(window.innerWidth < 10000){
            this.setState({show: false});
        }

        if (window.location.href.indexOf("/sro/krok-1") != -1)
        {

        }
        else if(window.location.href.indexOf("/sro/krok-2") != -1){

            this.two();
        }
        else if(window.location.href.indexOf("/sro/krok-3") != -1){
            this.three();

        }
        else if(window.location.href.indexOf("/sro/krok-4") != -1){
            this.four();


        }

        this.setState({
            allowrender: true,
        })

        var thirdSubmit = localStorage.getItem("thirdSubmit");
        console.log(thirdSubmit);

        if(thirdSubmit != null && thirdSubmit != 'proccess'){
            thirdSubmit = JSON.parse(thirdSubmit);

            var doplnkove = 0;

            thirdSubmit.forEach(function (e) {
                if(e.type != 'Voľná živnosť'){
                    doplnkove = doplnkove + e.price;
                }

            }.bind(this));

            this.setState({
                zivnostiCena: doplnkove,
            })

        }

    }
    closeCena(){
        this.setState({
            show:false,
        })
    }
    showCena(){
        this.setState({
            show:true,
        })
    }
    addZivnostCena(cena){
        this.setState({zivnostiCena: cena});
    }

    thirdSubmit(args){
        this.setState({
            thirdSubmit: args,
        })
    }

    fourthSubmit(args){
        this.setState({
            fourthSubmit: args,
        });
        console.log(args);
    }

    addDoplnkove(array){
        console.log(array);


        var doplnkove = new Array();

        doplnkove.push(array);

        console.log(doplnkove);
        this.setState({doplnkove: doplnkove});

    }
    makeFirst(){
        this.setState({
            first: true,
            second: false,
            third: false,
            fourth: false,
            fifth: false,
        })
    }

    makeFifth(){
        this.setState({
            first: false,
            second: false,
            third: false,
            fourth: false,
            fifth: true,
        })
    }

    firstSubmit(args){
        this.setState({
            firstSubmit: args,
        });

    }

    secondSubmit(args){
        this.setState({
            secondSubmit: args,
        });

    }

    makeSecond(){
        this.setState({
            first: false,
            second: false,
            third: false,
            fourth: true,
            fifth: false,
        })

    }

    makeThird(){
        this.setState({
            first: false,
            second: false,
            third: true,
            fourth: false,
            fifth: false,
        })
    }

    makeFourth(){
        this.setState({
            first: true,
            second: false,
            third: false,
            fourth: false,
            fifth: false,
        })
    }
    one(){
        if(localStorage.getItem("thirdSubmit") != null) {
            this.setState({
                first: true,
                second: false,
                third: false,
                fourth: false,
                fifth: false,
            })
        }
    }
    two(){
        if(localStorage.getItem("secondSubmit") != null) {
            this.setState({
                first: false,
                second: true,
                third: false,
                fourth: false,
                fifth: false,
            })
        }
    }
    three(){
        if(localStorage.getItem("thirdSubmit") != null) {
            this.setState({
                first: false,
                second: false,
                third: true,
                fourth: false,
                fifth: false,
            })
        }
    }
    four(){
        if(localStorage.getItem("fourthSubmit")!= null) {
            this.setState({
                first: false,
                second: false,
                third: false,
                fourth: true,
                fifth: false,
            })
        }
    }



    render() {
        return (

            <div className="App">

                <div className="main">

                    <div className="panel">
                        <div className="navigation">
                            <a className={`${localStorage.getItem("secondSubmit") != null ? "btn btn-allowed" : "btn btn-allowed"}${this.state.linka == 'two' ? " active" : ""}`} onClick={this.two}>1. Podnikateľské údaje</a>
                            <a className={`${localStorage.getItem("thirdSubmit") != null ? "btn btn-allowed" : "btn"}${this.state.linka == 'one' ? " active" : ""}`} onClick={this.one}>2. Predmety podnikania</a>
                            <a className={`${localStorage.getItem("fourthSubmit") != null ? "btn btn-allowed" : "btn"}${this.state.linka == 'fourth' ? " active" : ""}`} onClick={this.four}>3. Dokončenie</a>
                            <br/><br/>
                        </div>
                        {this.state.first == true && this.state.allowrender ?
                            <Zivnosti addlinka={this.addlinka} addZivnostCena={this.addZivnostCena} addDoplnkove={this.addDoplnkove} thirdSubmit={this.thirdSubmit} makeSecond={this.makeSecond} />
                            : null}
                        {this.state.second == true && this.state.allowrender ?
                            <Udaje addlinka={this.addlinka} addZivnostCena={this.addZivnostCena} addDoplnkove={this.addDoplnkove} secondSubmit={this.secondSubmit} makeFourth={this.makeFourth} />
                            : null}
                        {this.state.fourth == true && this.state.allowrender ?
                            <CompanyReview zivnostiCena={this.state.zivnostiCena} price={this.state.price} makeFifth={this.makeFifth} addlinka={this.addlinka} fourthSubmit={this.fourthSubmit} addZivnostCena={this.addZivnostCena} addDoplnkove={this.addDoplnkove} firstSubmit={this.secondSubmit} makeFourth={this.makeFourth} />
                            : null}
                        {this.state.fifth == true  && this.state.allowrender ?
                            <CompanyFinish firstSubmit={this.state.firstSubmit} secondSubmit={this.state.secondSubmit} makeFifth={this.makeFifth} thirdSubmit={this.state.thirdSubmit} fourthSubmit={this.state.fourthSubmit} />
                            : null}


                    </div>







                </div>



            </div>



        );
    }
}

export default App;
